import React from "react";
import Error404 from "../components/404/404";

export default function NotFound() {
    return (
        <>
            <Error404/>            
        </>

    )
}