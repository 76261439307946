
import facebook from "../../../images/icons/facebook.png";
import instagram from "../../../images/icons/instagram.png";



const data = [

      {
        id: 1,
        title: "Facebook | Decoratie Degezelle",
        text: "Facebook | Decoratie Degezelle",
        icon: facebook,
        link: "https://www.facebook.com/people/Decoratie-Degezelle/100090360757782/",
      },
      {
        id: 2,
        title: "Instagram | @decoratie_degezelle",
        text: "Instagram | @decoratie_degezelle",
        icon: instagram,
        link: "https://www.instagram.com/decoratie_degezelle/",
      },
      // Add more social media items as needed

];


export default data;
